<template>
  <div>
    <el-table v-loading="loading" :data="tableData" style="width: 100%;border-radius: 12px;"
      :row-class-name="tableRowClassName">
      <table-column prop="name" :label="$t('msgTemplate.name')"></table-column>
      <table-column v-if="editAuth" :label="$t('common.operation')" :width="60" align="center" :tooltip="false">
        <template #default="scope">
          <el-button type="text" @click="handleEdit(scope.row)">{{ $t("common.edit") }}</el-button>
        </template>
      </table-column>
    </el-table>
    <edit-dialog ref="editPage" @save-success="getList($event ? 1 : currentPage)"></edit-dialog>
  </div>
</template>
<script>
import EditDialog from "./MsgTemplateEdit.vue";

const moduleName = "msgTemplates";

export default {
  components: { EditDialog },
  data() {
    return {
      editAuth: this.$auth(33),
      loading: false,
      tableData: [],
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "highting-row";
      }
    },

    getList() {
      this.loading = true;
      this.$api.getList(moduleName).then(response => {
        this.loading = false;
        this.tableData = response.data;
      }).catch(() => {
        this.loading = false;
        this.$message.error(this.$t("common.tip.getDataError"));
      });
    },
    handleEdit(row) {
      this.$refs.editPage.open(row.id);
    },
  },
};
</script>
<style lang="scss" scoped></style>
